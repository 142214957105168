import { graphql, PageProps } from 'gatsby';
import React from 'react';
import LayoutMain from '../components/layouts/LayoutMain';
import SEO from '../components/seo/SEO';
import LayoutTotLife from "../components/layouts/layoutTotLife";

type PageContext = { uid: string; title: string };

const TotLifeCategory = ({ data, pageContext }: PageProps<TopicPageQuery, PageContext>) => {
  return (
    <LayoutMain>
      <SEO title={`${pageContext.title} Category`} />
        <LayoutTotLife
            topicId={pageContext.uid}
            articleSummaries={data.allPrismicWelltotBlogBlog.edges}
        />
    </LayoutMain>
  );
};

export const query = graphql`
  query TopicPage($uid: String) {
    allPrismicWelltotBlogBlog(filter: { data: { tags1: { elemMatch: { tags: { uid: { eq: $uid } } } } } }) {
      edges {
        ...ArticleSummary
      }
    }
  }
`;

export default TotLifeCategory;
